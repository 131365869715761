import "./../node_modules/jquery/dist/jquery";
import "./styles/main.scss";
import "./../node_modules/bootstrap/js/src/collapse";
import "./../node_modules/bootstrap/js/src/util";
import "owl.carousel";
import "@lottiefiles/lottie-player";
import { loadAnimation } from "lottie-web";
import { defineLordIconElement } from "lord-icon-element";
import collective from "./json/collective.json";
import projects from "./json/project-png.json";

let navItems = document.querySelectorAll(".nav-item");

burger.addEventListener("click", () => {
  if (document.documentElement.scrollWidth < "768") {
    if (burger.classList.contains("open") === false) {
      burger.classList.add("open");
      navbarSupportedContent.classList.add("show");
      navbarSupportedContent.style = "display:block";
      navItems.forEach((item) => {
        item.addEventListener("click", () => {
          navbarSupportedContent.style = "display:none";
          burger.classList.remove("open");
          navbarSupportedContent.classList.remove("show");
        });
      });
    } else {
      burger.classList.remove("open");
      navbarSupportedContent.classList.remove("show");
      navbarSupportedContent.style = "display:none";
    }
  }
});

const projectsUrls = [
  "/sh-project",
  "/mm-project",
  "/asp-project",
  "/ak-project",
  "/pr-project",
  "/kch-project",
  "/ad-project",
  "/kt-project",
  "/bds-project",
  "/privacy-policy",
];

if (projectsUrls.includes(window.location.pathname)) {
  const indexOfProject = projectsUrls.findIndex(
    (item) => item === window.location.pathname
  );

  const images = projects.map((item) => item.pngArr)[indexOfProject];

  images.map((item, key) => {
    $(".owl-carousel").append(
      `<div class="item"><div class="name" id="data${key}"></div></div>`
    );
    $(`#data${key}`).append(`<img src="${item}">`);
  });

  defineLordIconElement(loadAnimation);
  $(document).ready(function () {
    $(".owl-carousel").owlCarousel({
      responsive: {
        0: {
          items: 1,
        },
        992: {
          items: 1,
        },
      },
      loop: true,
      rewind: true,
      nav: true,
      navText: [
        '<svg width="14" height="26" viewBox="0 0 14 26" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 24L3.22304 14.3453C2.52955 13.5825 2.52955 12.4175 3.22304 11.6547L12 2" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/></svg>',
        '<svg width="14" height="26" viewBox="0 0 14 26" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 2L10.777 11.6547C11.4705 12.4175 11.4705 13.5825 10.777 14.3453L2 24" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/></svg>',
      ],
      dots: false,
    });
  });
} else {
  collective.map((item, key) => {
    $(".owl-carousel").append(
      `<div class="item"><div class="name" id="data${key}"></div></div>`
    );
    $(`#data${key}`).append('<img src="' + item.png + '">');
    $(`#data${key}`).append(
      '<h4 class="text-center">' + item.name + " " + item.surname + "</h4>"
    );
    $(`#data${key}`).append('<p class="text-center">' + item.role + "</p>");
  });

  defineLordIconElement(loadAnimation);
  $(document).ready(function () {
    $(".owl-carousel").owlCarousel({
      responsive: {
        0: {
          items: 1,
        },
        992: {
          items: 3,
        },
      },
      loop: true,
      center: true,
      nav: true,
      navText: [
        '<svg width="14" height="26" viewBox="0 0 14 26" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 24L3.22304 14.3453C2.52955 13.5825 2.52955 12.4175 3.22304 11.6547L12 2" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/></svg>',
        '<svg width="14" height="26" viewBox="0 0 14 26" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 2L10.777 11.6547C11.4705 12.4175 11.4705 13.5825 10.777 14.3453L2 24" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/></svg>',
      ],
      dots: false,
    });
  });
}
